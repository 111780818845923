@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&display=swap');

* {
    font-family: 'Manrope', sans-serif;
}

.body {
	/* background-color: #f4e9f5; */
    background-color: white;
}

.map-container {
    width: 600px;
    height: 450px;
    border-radius: 12px!important;
}

.mapboxgl-ctrl-logo {
    display: none!important;
}